import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import { withFirebase, FirebaseProps } from '../../components/Firebase';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import { AuthUserContext, withAuthorization } from '../../components/Session';

import {
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    LinearProgress, Grid, Typography, InputAdornment, Toolbar, Avatar, IconButton, Divider
} from '@material-ui/core';

import UserAvatar from "../../components/useravatar/UserAvatar";
import GameCard from "../../components/gamecard/GameCard";
import Layout from "../../containers/layout/Layout";
import ShareDialog from "../../components/sharedialog/Share";

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';

import logo from '../../images/logo_primary.svg';

import { getGameShareUrl } from '../../helpers/gamehelpers';

interface GamesProps extends RouteComponentProps, FirebaseProps, WithStyles<typeof styles> {
}

interface GamesState {
    loading?: boolean;
    games?: GameScore[];

    deleteGame?: GameScore;

    openShare?: boolean;
    shareUrl?: string;
}

class Games extends Component<GamesProps, GamesState> {

    constructor(props: GamesProps) {
        super(props);

        this.state = {
            loading: true,
            games: []
        }
    }

    componentDidMount() {
        this.props.firebase.onAuthUserListener((user: AuthUser) => {
            if (user) {
                const uid = user.uid;
                this.props.firebase.games()
                    .where("createdBy", "==", uid)
                    .orderBy("createdAt", "desc")
                    .onSnapshot(snapshot => {
                        if (snapshot.size) {
                            let games: GameScore[] = [];
                            snapshot.forEach(doc => {
                                const gameData = doc.data();
                                games.push({ ...gameData, gameId: doc.id });
                            });

                            this.setState({
                                games: games,
                                loading: false,
                            });
                        } else {
                            this.setState({ games: [], loading: false });
                        }
                    })
            }
        });
    }

    handleLogout = () => {
        this.props.firebase.doSignOut().then(() => {
            this.props.history.push(ROUTES.LANDING);
        });
    }

    handleProfileMenuOpen = () => {

    }

    handleDeleteGame = (game: GameScore) => {
        this.setState({
            deleteGame: game
        })
    }

    handleShareGame = (game: GameScore) => {
        this.setState({
            openShare: true,
            shareUrl: getGameShareUrl(game)
        })
    }

    handleCloseShare = () => {
        this.setState({
            openShare: false,
            shareUrl: undefined
        });
    }

    handleNativeShare = (url: string) => {
        if ((navigator as any).share) {
            (navigator as any).share({
                title: 'Chachoka.com',
                text: 'Check out this game of Chachoka!',
                url: url,
            })
                .then(() => console.log('Successful share'))
        }
    }

    handleConfirmedDeleteGame = () => {
        const { deleteGame } = this.state;
        this.setState({ deleteGame: undefined });

        this.props.firebase.game(deleteGame.gameId).delete().then(function () {
            console.log("Document successfully deleted!");
        }).catch(function (error) {
            console.error("Error removing document: ", error);
        });

        // var deleteFn = this.props.firebase.functions().httpsCallable('recursiveDelete');
        // deleteFn({ path: `/games/${deleteGame.gameId}` })
        //     .then((result: any) => {
        //         console.log('Delete success: ' + JSON.stringify(result));
        //     });
    }

    handleDialogClose = () => {
        this.setState({ deleteGame: undefined });
    }

    render() {
        const { classes } = this.props;
        const { games, loading, deleteGame, openShare, shareUrl } = this.state;

        // Sort games into completed and on-going games. 
        const ongoingGames = games.filter(game => !game.completed);
        const completedGames = games.filter(game => game.completed);

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <Layout loading={loading} landing={true}>
                        <Grid container spacing={4} className={classes.classrooms}>
                            {!loading && <Grid item>
                                <GameCard game={undefined} history={this.props.history} />
                            </Grid>}
                            {!loading && ongoingGames.map(game =>
                                <Grid item key={'grid' + game.gameId}>
                                    <GameCard
                                        key={game.gameId}
                                        game={game}
                                        history={this.props.history}
                                        onShareGame={this.handleShareGame}
                                        onDeleteGame={this.handleDeleteGame} />
                                </Grid>
                            )}
                        </Grid>

                        {!loading && completedGames.length ?
                            <React.Fragment>
                                <Typography variant="h3" className={classes.previousGamesTitle}>
                                    Finished Games
                                </Typography>
                                <Grid container spacing={4} className={classes.classrooms}>
                                    {!loading && completedGames.map(game =>
                                        <Grid item key={'completedgrid' + game.gameId}>
                                            <GameCard
                                                key={game.gameId}
                                                game={game}
                                                history={this.props.history}
                                                onShareGame={this.handleShareGame}
                                                onDeleteGame={this.handleDeleteGame} />
                                        </Grid>
                                    )}
                                </Grid>
                            </React.Fragment>
                        : undefined}

                        <Dialog
                            open={!!deleteGame}
                            onClose={this.handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{`Delete Game?`}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {`Your game will be deleted from our servers. No undos!`}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDialogClose} color="secondary">
                                    No
                                </Button>
                                <Button onClick={this.handleConfirmedDeleteGame} color="primary" autoFocus>
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <ShareDialog open={openShare} shareUrl={shareUrl} onClose={this.handleCloseShare} />
                    </Layout>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100vh',
            backgroundColor: '#ECEFF1',
            display: 'flex',
            flexDirection: 'column'
        },
        appBar: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
        },
        header: {
            backgroundColor: 'white',
            //   backgroundImage: 'url(./landing_header.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100% 0',
            height: '448px',
            marginBottom: '-122px',
            [theme.breakpoints.up('lg')]: {
                backgroundPosition: 'right -160px top',
            },
            [theme.breakpoints.only('md')]: {
                backgroundPosition: 'calc(50% + 380px) 0',
            },
            [theme.breakpoints.only('sm')]: {
                backgroundPosition: '232px 0',
                height: '400px',
                marginBottom: '-88px',
            },
            [theme.breakpoints.only('xs')]: {
                backgroundPosition: 'right -572px top',
            },
        },
        grow: {
            flexGrow: 1,
        },
        logotype: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(12)
        },
        logotypeText: {
            fontWeight: 500,
            color: theme.palette.secondary.main,
            marginLeft: theme.spacing(1),
        },
        logotypeIcon: {
            width: 40,
            marginRight: theme.spacing(1),
        },
        avatarButton: {
            margin: 0,
        },
        landingWelcome: {
            padding: '64px 0 52px 0',
        },
        landingGrid: {
            padding: '0 24px',
        },
        title: {
            fontSize: 30,
            fontWeight: 400,
            marginBottom: theme.spacing(2),
        },
        subtitle: {
            color: 'rgba(0,0,0,0.54)',
            fontSize: 16
        },
        previousGamesTitle: {
            marginLeft: theme.spacing(3),
        },
        classrooms: {
            width: 'calc(100% + 16px)',
            flexGrow: 1,
            padding: '2rem'
        },
        classroomsSpinner: {
            position: 'absolute',
            width: '100%',
        },
    });

const condition = (authUser: firebase.User) => !!authUser;

export default withAuthorization(condition)(withStyles(styles)(withFirebase(Games)));