import React, { Component } from 'react';

import classNames from 'classnames';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Fab, ButtonBase, Grid, Paper, Typography, TextField } from '@material-ui/core';

import Backspace from '@material-ui/icons/Backspace';
import Check from '@material-ui/icons/Check';

import Card from '../../images/card.svg';
import JokerCard from '../../images/joker-card.svg';

interface CalculatorProps extends WithStyles<typeof styles> {
    onComplete?: (value: number) => void;
}

interface CalculatorState {
    currentValue: number;
    undoStack?: number[];
}

const CARD_VALUES = [2, 3, 4, 5, 6, 7, 8, 9, 10, 10, 10, 10, 20];
const CARDS = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];

class Calculator extends Component<CalculatorProps, CalculatorState> {

    constructor(props: CalculatorProps) {
        super(props);

        this.state = {
            currentValue: 0,
            undoStack: []
        }
    }

    handleCardClick = (cardIndex: number) => {
        const value = CARD_VALUES[cardIndex];
        this.handleSpecificCardClick(value);
    }

    handleSpecificCardClick = (value: number) => {
        const undoStack = this.state.undoStack;
        undoStack.push(value);
        this.setState({
            undoStack,
            currentValue: this.state.currentValue + value
        });
    }

    handleBackspace = () => {
        const undoStack = this.state.undoStack;
        if (undoStack.length > 0) {
            const lastValue = undoStack.pop();
            this.setState({
                undoStack,
                currentValue: this.state.currentValue - lastValue
            })
        }
    }

    handleDone = () => {
        const { onComplete } = this.props;
        if (onComplete) onComplete(this.state.currentValue);
    }

    render() {
        const { classes } = this.props;
        const { currentValue } = this.state;

        return (
            <Grid container className={classes.container} spacing={2}>
                <Grid item xs={12}>
                    <Paper className={classes.paperTextField} elevation={0}>
                        <div className={classes.paperCurrentVal}>
                            {currentValue}
                        </div>

                        <ButtonBase tabIndex={0} className={classes.cardButton} onClick={this.handleBackspace}>
                            <Paper className={classNames(classes.cardPaper, classes.donePaper)} elevation={0}>
                                <Backspace className={classes.backspaceIcon} />
                            </Paper>
                        </ButtonBase>
                    </Paper>
                </Grid>

                {CARDS.map((card, cardIndex) =>
                    <Grid item xs={4}>
                        {card && (
                            <ButtonBase tabIndex={0} className={classes.cardButton} onClick={() => this.handleCardClick(cardIndex)}>
                                <Paper className={classes.cardPaper} elevation={4}>
                                    <img className={classes.logotypeIcon} src={Card} alt="Card" />
                                    <Typography variant="h6" className={classNames(classes.cardName, {
                                        [classes.cardNameTwoDigits]: cardIndex === 8
                                    })}>{card}</Typography>
                                </Paper>
                            </ButtonBase>
                        )}
                    </Grid>
                )}

                <Grid item xs={4}>
                    <ButtonBase tabIndex={0} className={classes.cardButton} onClick={() => this.handleSpecificCardClick(20)}>
                        <Paper className={classes.cardPaper} elevation={4}>
                            <img className={classes.logotypeIcon} src={JokerCard} alt="Joker Card" />
                        </Paper>
                    </ButtonBase>
                </Grid>

                <Grid item xs={4}>
                    <Fab size="large" aria-label="Done" className={classes.fab} onClick={this.handleDone}>
                        <Check style={{ fontSize: 50 }} />
                    </Fab>
                </Grid>

            </Grid>
        )
    }
}

const styles = (theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
        },
        paperTextField: {
            padding: 0,
            fontSize: 60,
            minHeight: 75,
            display: 'flex'
        },
        paperCurrentVal: {
            flexGrow: 1,
        },
        cardButton: {
        },
        cardPaper: {
            padding: theme.spacing(1),
            minWidth: 60,
            minHeight: 75,
            position: 'relative',
            '&:active': {
                background: 'black',
            }
        },
        cardName: {
            position: 'absolute',
            fontSize: 30,
            color: theme.palette.common.white,
            top: theme.spacing(2),
            left: 'calc(50% - 10px)',
        },
        cardNameTwoDigits: {
            left: 'calc(50% - 17px)',
        },
        donePaper: {
            padding: theme.spacing(2),
        },
        doneIcon: {
            color: (theme.palette as any).success.main,
            fontSize: 50
        },
        backspaceIcon: {
            color: (theme.palette as any).success.main,
            fontSize: 50
        },
        logotypeIcon: {
            width: 60,
        },
        fab: {
            height: 80,
            width: 80,
            margin: theme.spacing(2),
            color: (theme.palette as any).success.main,
        },
    });


export default withStyles(styles)(Calculator);