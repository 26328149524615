import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { withFirebase, FirebaseProps } from '../../components/Firebase';

import { AuthUserContext, withAuthorization } from '../../components/Session';
import Hashids from 'hashids';
import CopyToClipboard from 'react-copy-to-clipboard';

import { Link } from 'react-router-dom';
import { ButtonBase, Card, CardContent } from '@material-ui/core';

import { MobileStepper, LinearProgress } from '@material-ui/core';
import { Grid, Paper, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, Input, InputAdornment, IconButton, Chip, Avatar, Divider } from '@material-ui/core';
import { createStyles, Theme, withTheme, WithTheme, withStyles, WithStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';

import FaceIcon from '@material-ui/icons/Face';
import CalculatorIcon from '@material-ui/icons/DragIndicator';

import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import ScoreSheet, { ROUND_NAMES, FULL_ROUND_NAMES } from '../../components/scoresheet/ScoreSheet';
import Calculator from '../../components/calculator/Calculator';

import Error from '../../containers/error/Error';
import ShareDialog from '../../components/sharedialog/Share';
import Footer from '../../containers/layout/Footer';

import { updateGameTotals, getWinningPlayer, calculateRoundScore, getFullPlayerName, getShortPlayerName, getGameShareUrl } from '../../helpers/gamehelpers';

interface GameProps extends FirebaseProps, RouteComponentProps, WithStyles<typeof styles>, WithTheme {
}

type GameView = 'round' | 'completed' | 'share';

interface GameState {
    gameId: string;
    loading?: boolean;

    game?: GameScore;

    view?: GameView;
    openScore?: boolean;

    openShare?: boolean;

    madeCut?: boolean;
    winningPlayer?: number;
    roundScores?: number[];

    openCalculator?: boolean;
    calculatingFor?: number;
}

class Game extends Component<GameProps, GameState> {

    private unsubscribe: firebase.Unsubscribe;

    constructor(props: GameProps) {
        super(props);

        const { match: { params } } = this.props;
        const gameId = (params as any).gid;

        this.state = {
            view: 'round',
            loading: true,
            gameId,
            winningPlayer: -1,
            madeCut: false,
            roundScores: [],
            openScore: false,
            openShare: false,
            openCalculator: false,

            // game: {
            //     gameId: 'test',
            //     completed: true,
            //     currentRound: 8,
            //     startingPlayer: 0,
            //     players: [{
            //         name: 'Sophia'
            //     }, {
            //         name: 'Arianna'
            //     }, {
            //         name: 'Caroline'
            //     }, {
            //         name: 'Sam'
            //     },
            //     {
            //         name: 'Slom'
            //     }],
            //     scores: [[
            //         { roundScore: 0, isWonRound: true },
            //         { roundScore: 30 },
            //         { roundScore: 40 },
            //         { roundScore: 10 },
            //         { roundScore: 10 },
            //     ],
            //     [
            //         { roundScore: 30 },
            //         { roundScore: 20, isWonCut: true },
            //         { roundScore: 40 },
            //         { roundScore: 0, isWonRound: true },
            //         { roundScore: 10 },
            //     ],
            //     [
            //         { roundScore: 30 },
            //         { roundScore: 20 },
            //         { roundScore: 40, isWonCut: false },
            //         { roundScore: 0, isWonRound: true },
            //         { roundScore: 10 },
            //     ],
            //     [
            //         { roundScore: 0, isWonRound: true },
            //         { roundScore: 30 },
            //         { roundScore: 40 },
            //         { roundScore: 20 },
            //         { roundScore: 10 },
            //     ],
            //     [
            //         { roundScore: 0, isWonRound: true },
            //         { roundScore: 30 },
            //         { roundScore: 40 },
            //         { roundScore: 20 },
            //         { roundScore: 10 },
            //     ],
            //     [
            //         { roundScore: 0, isWonRound: true },
            //         { roundScore: 30 },
            //         { roundScore: 40 },
            //         { roundScore: 20 },
            //         { roundScore: 10 },
            //     ],
            //     [
            //         { roundScore: 0, isWonRound: true },
            //         { roundScore: 30 },
            //         { roundScore: 40 },
            //         { roundScore: 20 },
            //         { roundScore: 10 },
            //     ],
            //     [
            //         { roundScore: 0, isWonRound: true },
            //         { roundScore: 30 },
            //         { roundScore: 40 },
            //         { roundScore: 20 },
            //         { roundScore: 10 },
            //     ],
            //     [
            //         { roundScore: 0, isWonRound: true },
            //         { roundScore: 30 },
            //         { roundScore: 40 },
            //         { roundScore: 20 },
            //         { roundScore: 10 },
            //     ]
            //     ]
            // }
        }
    }

    componentDidMount() {
        this.onListenForGame(this.props, this.state.gameId);
    }

    componentWillReceiveProps(nextProps: GameProps, prevState: GameState) {
        const { match: { params } } = nextProps;
        const newGameId = (params as any).gid;
        if (newGameId !== prevState.gameId) {
            // Unlisten to previous game
            this.unListenForGame();
            this.setState({
                loading: true,
                gameId: newGameId
            });
            // Listen to new game
            this.onListenForGame(nextProps, newGameId);
        }
    }

    onListenForGame(props: GameProps, gameId: string) {
        this.unsubscribe = props.firebase
            .game(gameId)
            .onSnapshot(doc => {
                if (!doc.exists) {
                    this.setState({
                        loading: false
                    })
                    return;
                }

                const gameData = doc.data();

                const game: GameScore = {
                    gameId: gameId,
                    ...gameData
                };

                const currentUserUid = (props.firebase.currentUser() && props.firebase.currentUser().uid);
                const isOwner = game.createdBy === currentUserUid;

                const newState: GameState = {
                    gameId: this.state.gameId
                };
                newState.game = game;
                newState.loading = false;
                if (!isOwner) newState.view = 'share';
                if (game.completed) newState.view = 'completed';

                this.setState(newState);
            });
    }

    unListenForGame() {
        if (this.unsubscribe) this.unsubscribe();
    }

    componentWillUnmount() {
        this.unListenForGame();
    }

    handleCloseScore = () => {
        this.setState({
            openScore: false
        })
    }

    handleCloseCalculator = () => {
        this.setState({
            openCalculator: false
        })
    }

    handleCalculatorClose = (value: number) => {
        this.handlePlayerScoreChange(this.state.calculatingFor, value);
        this.handleCloseCalculator();
    }

    handlePlayerScoreChange = (playerIndex: number, value: number) => {
        let roundScores = this.state.roundScores;
        roundScores[playerIndex] = value ? Number(value) : undefined;
        this.setState({ roundScores });
    }

    handleWinningPlayer = (playerIndex: number) => {
        let roundScores = this.state.roundScores;
        roundScores[playerIndex] = undefined;
        this.setState({ roundScores, winningPlayer: playerIndex });
    }

    isNextRoundReady = () => {
        const { winningPlayer, roundScores, game } = this.state;
        if (winningPlayer === -1) return false;

        for (let i = 0; i < game.players.length; i++) {
            if (i === winningPlayer) continue;
            if (!roundScores[i]) return false;
        }
        return true;
    }

    getCurrentDealingPlayer = () => {
        const { game } = this.state;
        return ((game.startingPlayer + game.currentRound - 1) % game.players.length);
    }

    getCurrentStartingPlayer = () => {
        const { game } = this.state;
        return ((game.startingPlayer + game.currentRound) % game.players.length);
    }

    handlePreviousRound = () => {
        this.loadRound(this.state.game.currentRound - 1);
    }

    handlePrevious = () => {
        const game = this.state.game;
        this.saveRound(game.currentRound, game);

        this.setState({
            game,
            view: 'round'
        })
    }

    handleNextRound = () => {
        const game = this.state.game;
        this.saveRound(game.currentRound, game);
        game.currentRound = game.currentRound + 1;

        this.loadRound(game.currentRound);

        // const startingPlayer = this.getCurrentStartingPlayer();
        // const didMakeCut = game.scores[game.currentRound] && game.scores[game.currentRound].scores[startingPlayer]
        //     && game.scores[game.currentRound].scores[startingPlayer].isWonCut || false;

        this.setState({
            game
        })
    }

    handleCompleteGame = () => {
        const game = this.state.game;
        this.saveRound(game.currentRound, game);

        // Compute totals
        updateGameTotals(game);

        // Set winner
        game.completed = true;
        const { index: winningPlayer } = getWinningPlayer(game);
        game.winner = winningPlayer;

        this.saveRound(game.currentRound, game);
        game.currentRound++; // Final increment

        this.setState({
            game,
            view: 'completed',
            winningPlayer: -1,
            roundScores: [],
            madeCut: false
        })
    }

    handleRoundComplete = () => {
        this.loadRound(this.state.game.currentRound, this.state.madeCut);
    }

    loadRound = (currentRound: number, didMakeCut?: boolean) => {
        if (currentRound > 8) return;
        // Load next/previous round if it's available, otherwise set defaults.
        let winningPlayer = -1;
        let roundScores: number[] = [];
        let madeCut = didMakeCut || false;

        const game = this.state.game;
        game.currentRound = currentRound;
        const startingPlayer = this.getCurrentStartingPlayer();
        if (game.scores[currentRound]) {
            game.players.forEach((player, playerIndex) => {
                const playerScore = game.scores[currentRound].scores[playerIndex];
                if (playerScore) {
                    if (playerScore.isWonRound) winningPlayer = playerIndex;
                    if (startingPlayer === playerIndex && playerScore.isWonCut) madeCut = true;

                    roundScores[playerIndex] = playerScore.roundScore || undefined;
                }
            })
        }

        this.setState({
            winningPlayer: winningPlayer,
            madeCut: madeCut,
            roundScores: roundScores,
            openScore: false,
            openCalculator: false
        })
    }

    saveRound = (currentRound: number, game: GameScore) => {
        const roundScores = this.state.roundScores;
        const winningPlayer = this.state.winningPlayer;
        const startingPlayer = this.getCurrentStartingPlayer();
        const madeCut = this.state.madeCut;

        for (let i = 0; i < game.players.length; i++) {
            const score: Score = {
                roundScore: 0
            };

            if (startingPlayer === i && madeCut) score.isWonCut = true;
            if (winningPlayer === i) {
                score.isWonRound = true;
            } else if (roundScores[i]) {
                score.roundScore = roundScores[i];
            } else {
                score.roundScore = 0;
                //continue;
            }

            if (!game.scores[currentRound]) game.scores[currentRound] = {
                scores: []
            };
            game.scores[currentRound].scores[i] = score;
        }

        // Save to firebase
        const gameRecord = this.props.firebase.game(this.state.gameId);
        gameRecord.get().then(snapshot => snapshot.data())
            .then((gameData: any) => {
                // Convert nested array to 
                Object.assign(gameData, game);
                gameRecord.set(gameData);
            });

        this.setState({
            game
        })
    }

    handleShareButton = () => {
        this.setState({
            openShare: true
        })
    }

    handleCloseShare = () => {
        this.setState({
            openShare: false
        })
    }

    handleAllGamesButton = () => {
        this.props.history.push(ROUTES.LANDING);
    }

    render() {
        const { classes, theme } = this.props;
        const { game, view, openScore, openShare, madeCut, winningPlayer, roundScores, openCalculator, loading } = this.state;

        if (loading) return <div className={classes.loadingProgress}><LinearProgress /></div>;
        if (!game) return <Error />;

        const currentRoundName = ROUND_NAMES[game.currentRound];
        const currentLongRoundName = FULL_ROUND_NAMES[game.currentRound];

        const currentStartingPlayer = this.getCurrentStartingPlayer();
        const currentDealingPlayer = this.getCurrentDealingPlayer();

        const startingPlayerName = getFullPlayerName(game.players[currentStartingPlayer].name);
        const dealingPlayersName = getFullPlayerName(currentDealingPlayer == -1 ? game.players[game.players.length - 1].name : game.players[currentDealingPlayer].name);

        const shareUrl = getGameShareUrl(game);

        const scoreSheetComp = <Grid container className={classes.scoreContainer}>
            {view === 'share' || view === 'completed' ? <ScoreSheet game={game} /> :
                <ScoreSheet game={game} onClose={this.handleCloseScore} />}
        </Grid>;

        if (view === 'share') return scoreSheetComp;

        let winningPlayerIndex: number;
        let winningPlayerName: string;

        if (view === 'completed') {
            // Calculate totals
            updateGameTotals(game);

            const { index, name } = getWinningPlayer(game);
            winningPlayerIndex = index;
            winningPlayerName = name;
        }

        return (
            <main className={classes.mainContainer}>
                <Grid container className={classes.container}>
                    <Grid container className={classes.containerInner}>
                        {view === 'round' && (
                            <React.Fragment>
                                <Avatar className={classNames(classes.roundNameAvatar, {
                                    [classes.roundNameAvatarMadeCut]: madeCut
                                })}>
                                    {currentRoundName}
                                </Avatar>
                            </React.Fragment>
                        )}
                        {view === 'completed' && (
                            <Grid container justify="center">
                                <Grid item xs={12}>
                                    <Typography variant="h1" className={classes.winningName}>
                                        {`${winningPlayerName} Won`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.completedAvatarList}>
                                        {game.players.map((player, playerIndex) =>
                                            <Chip
                                                key={"chip-" + player.name}
                                                avatar={<Avatar>{getShortPlayerName(player.name)}</Avatar>}
                                                label={game.totals && game.totals[playerIndex]}
                                                className={classes.completedChips}
                                                size="small"
                                                color={playerIndex === winningPlayerIndex ? "secondary" : undefined}
                                            />
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {scoreSheetComp}
                                </Grid>
                            </Grid>
                        )}
                        {(view === 'round' || view === 'completed') && (
                            <div className={classes.toolbarButtons}>
                                <div className={classes.scorecardButtonWrapper}>
                                    <Button className={classes.scorecardButton} variant="outlined" onClick={this.handleAllGamesButton}>All Games</Button>
                                </div>
                                <div className={classes.scorecardButtonWrapper}>
                                    <Button className={classes.scorecardButton} variant="outlined" onClick={this.handleShareButton}>Share</Button>
                                    {view === 'round' ? <Button className={classes.scorecardButton} variant="outlined" onClick={() => this.setState({ openScore: true })}>Score</Button> : undefined}
                                </div>
                            </div>
                        )}
                        <Paper className={classes.paper}>
                            <div className={classes.root}>
                                {view === 'round' && (
                                    <React.Fragment>
                                        <div className={classes.section1}>
                                            <Grid container alignItems="flex-start">
                                                <Grid item xs>
                                                    <Typography gutterBottom variant="h4">
                                                        {currentLongRoundName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid>
                                                        <Grid item xs={12}>
                                                            <Chip
                                                                size="small"
                                                                icon={<FaceIcon />}
                                                                label={`${dealingPlayersName} ${view === 'round' ? 'Deals' : 'Dealt'}`}
                                                                className={classes.currentPlayerChip}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Chip
                                                                size="small"
                                                                color="secondary"
                                                                icon={<FaceIcon />}
                                                                label={`${startingPlayerName}${view === 'round' ? `'s Cut` : ' Cut'}`}
                                                                className={classes.currentPlayerChip}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider variant="middle" />
                                        <div className={classes.section2}>
                                            <Typography gutterBottom variant="body1">
                                                {`Did ${startingPlayerName} get the cut?`}
                                            </Typography>
                                            <div>
                                                <Chip className={classes.chip} color={!madeCut ? "primary" : undefined} label="No" onClick={() => this.setState({ madeCut: false })} clickable />
                                                <Chip className={classes.chip} color={madeCut ? "primary" : undefined} label="Yes" onClick={() => this.setState({ madeCut: true })} clickable />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}

                                {view === 'round' && (
                                    <React.Fragment>
                                        <Divider variant="middle" />
                                        <div className={classes.section2}>
                                            <Typography gutterBottom variant="body1">
                                                {`Who won?`}
                                            </Typography>
                                            <div>
                                                {game.players.map((player, playerIndex) =>
                                                    <Chip
                                                        key={"chip-" + player.name}
                                                        className={classes.chip} color={winningPlayer === playerIndex ? "primary" : undefined}
                                                        label={getFullPlayerName(player.name)} onClick={() => this.handleWinningPlayer(playerIndex)} clickable />
                                                )}
                                            </div>
                                        </div>
                                        {winningPlayer !== -1 && (
                                            <React.Fragment>
                                                <Divider variant="middle" />
                                                <div className={classes.section2}>
                                                    {game.players.map((player, playerIndex) =>
                                                        <TextField
                                                            id="outlined-adornment-password"
                                                            className={classes.playerScoreField}
                                                            variant="outlined"
                                                            label={getFullPlayerName(player.name)}
                                                            type="number"
                                                            value={roundScores[playerIndex]}
                                                            onChange={(e: any) => this.handlePlayerScoreChange(playerIndex, e.target.value)}
                                                            disabled={winningPlayer === playerIndex}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            tabIndex={-1}
                                                                            disabled={winningPlayer === playerIndex}
                                                                            edge="end"
                                                                            aria-label="Show Calculator"
                                                                            onClick={() => this.setState({ openCalculator: true, calculatingFor: playerIndex })}
                                                                        >
                                                                            <CalculatorIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                </div>

                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}

                                {view === 'round' && (
                                    <React.Fragment>
                                        <Divider variant="middle" />
                                        <div className={classes.section3}>
                                            {game.currentRound < 8 ? <Button color="primary" variant="contained" disabled={!this.isNextRoundReady()} onClick={this.handleNextRound}>Next Round</Button> : undefined}
                                            {game.currentRound === 8 ? <Button color="primary" variant="contained" disabled={!this.isNextRoundReady()} onClick={this.handleCompleteGame}>Complete Game</Button> : undefined}

                                            {game.currentRound > 0 ? <Button color="primary" onClick={this.handlePreviousRound}>Previous Round</Button> : undefined}
                                        </div>
                                    </React.Fragment>
                                )}

                            </div>
                        </Paper>

                        <Dialog fullScreen open={openScore} onClose={this.handleCloseScore} TransitionComponent={Transition}>
                            {scoreSheetComp}
                        </Dialog>

                        <Dialog fullScreen open={openCalculator} onClose={this.handleCloseCalculator} TransitionComponent={Transition}>
                            <Grid container className={classes.scoreContainer}>
                                <Calculator onComplete={this.handleCalculatorClose} />
                            </Grid>
                        </Dialog>

                        <ShareDialog open={openShare} onClose={this.handleCloseShare} shareUrl={shareUrl} />
                    </Grid>
                </Grid>

                <Footer />
            </main>
        );
    }
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        loadingProgress: {
            flexGrow: 1
        },
        chip: {
            marginRight: theme.spacing(1),
            padding: theme.spacing(1),
            fontSize: '1rem',
        },
        section1: {
            margin: theme.spacing(3, 2),
        },
        section2: {
            margin: theme.spacing(2),
        },
        section3: {
            margin: theme.spacing(3, 1, 1),
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
        },
        mainContainer: {
            minHeight: '100vh',
            backgroundColor: '#ECEFF1',
            display: 'flex',
            flexDirection: 'column'
        },
        container: {
            // minHeight: '100%',
            // height: '100vh',
            //width: '100vw',
            flexGrow: 1,
            //backgroundColor: theme.palette.primary.main,
            // position: 'absolute',
        },
        containerInner: {
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(1),
        },
        toolbarButtons: {
            position: 'absolute',
            width: '100%',
            top: theme.spacing(1),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        scorecardButtonWrapper: {
            margin: theme.spacing(1),
        },
        scorecardButton: {
            margin: theme.spacing(1) / 2,
        },
        scoreContainer: {
            // height: '100vh',
            //width: '100vw',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: theme.spacing(1),
            //backgroundColor: theme.palette.primary.main,
        },
        roundNameAvatar: {
            margin: 40,
            marginTop: 80,
            width: 200,
            height: 200,
            fontSize: 75,
            color: '#fff',
            backgroundColor: theme.palette.secondary.main,
        },
        roundNameAvatarMadeCut: {
            border: `7px solid ${theme.palette.primary.main}`,
        },
        roundNameText: {
            fontSize: 70,
        },
        roundNameLongText: {
            fontSize: 40,
        },
        currentPlayerText: {
            fontSize: 20
        },
        currentPlayerChip: {
            margin: theme.spacing(1) / 2,
        },
        paper: {
            width: '100%',
        },
        card: {
            display: 'flex',
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            flex: '1 0 auto',
        },
        cover: {
            width: 151,
        },
        playerScoreField: {
            margin: theme.spacing(1),
        },
        // Winning
        winningName: {
            margin: theme.spacing(1),
            marginTop: 60,
            fontSize: 60,
        },
        completedAvatarList: {
            display: 'flex',
            flexDirection: 'row',
        },
        completedChips: {
            margin: theme.spacing(1) / 2,
        },
    });

const condition = (authUser: firebase.User) => !!authUser;

export default withAuthorization(condition)(withTheme(withStyles(styles)(withFirebase(Game))));