import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import { AuthUserContext } from '../../components/Session';


import {
    Container, LinearProgress, Grid, Typography, AppBar, Toolbar, Avatar, IconButton, Divider
} from '@material-ui/core';

import UserAvatar from "../../components/useravatar/UserAvatar";

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';

import Footer from './Footer';

import logo from '../../images/logo_primary.svg';

interface LayoutProps extends WithStyles<typeof styles> {
    loading?: boolean;
    landing?: boolean;
}

class Layout extends Component<LayoutProps, {}> {

    render() {
        const { classes, loading, landing, children } = this.props;

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <main className={classes.container}>
                        {loading && (<LinearProgress className={classes.classroomsSpinner} />)}
                        <header className={classNames(classes.header, {
                            [classes.headerSmall]: !landing
                        })}>
                            <AppBar position="static" className={classes.appBar}>
                                <Toolbar variant="dense">
                                    <Link to={ROUTES.LANDING} className={classes.logotype}>
                                        <img className={classes.logotypeIcon} src={logo} alt="logo" />
                                        <Typography variant="h6" color="inherit" className={classes.logotypeText}>
                                            {CONFIG.APP_NAME}
                                        </Typography>
                                    </Link>
                                    <div className={classes.grow} />
                                    <UserAvatar authUser={authUser} className={classes.avatarButton} />
                                </Toolbar>
                            </AppBar>

                            {landing && (<div className={classes.landingWelcome}>
                                <Grid container className={classes.landingGrid}>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Typography variant="h2" className={classes.title}>Welcome to {CONFIG.APP_NAME}!</Typography>
                                        {/* <Typography className={classes.subtitle}>Classroom productivity tools for fun and engaging remote teaching.</Typography> */}
                                    </Grid>
                                </Grid>
                            </div>)}
                        </header>
                        
                        {children}

                        <Footer />
                    </main>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100vh',
            backgroundColor: '#ECEFF1',
            display: 'flex',
            flexDirection: 'column'
        },
        appBar: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
        },
        header: {
            backgroundColor: 'white',
            //   backgroundImage: 'url(./landing_header.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100% 0',
            height: '400px',
            marginBottom: '-122px',
            [theme.breakpoints.up('lg')]: {
                backgroundPosition: 'right -160px top',
            },
            [theme.breakpoints.only('md')]: {
                backgroundPosition: 'calc(50% + 380px) 0',
            },
            [theme.breakpoints.only('sm')]: {
                backgroundPosition: '232px 0',
                height: '300px',
                marginBottom: '-88px',
            },
            [theme.breakpoints.only('xs')]: {
                height: '300px',
                backgroundPosition: 'right -572px top',
            },
        },
        headerSmall: {
            height: '200px',
        },
        grow: {
            flexGrow: 1,
        },
        logotype: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
        },
        logotypeText: {
            fontWeight: 500,
            color: theme.palette.secondary.main,
            marginLeft: theme.spacing(1),
        },
        logotypeIcon: {
            width: 40,
            marginRight: theme.spacing(1),
        },
        avatarButton: {
            margin: 0,
        },
        landingWelcome: {
            padding: '64px 0 52px 0',
        },
        landingGrid: {
            padding: '0 24px',
        },
        title: {
            fontSize: 30,
            fontWeight: 400,
            marginBottom: theme.spacing(2),
        },
        subtitle: {
            color: 'rgba(0,0,0,0.54)',
            fontSize: 16
        },
        previousGamesTitle: {
            marginLeft: theme.spacing(3),
        },
        classrooms: {
            width: 'calc(100% + 16px)',
            flexGrow: 1,
            padding: '2rem'
        },
        classroomsSpinner: {
            position: 'absolute',
            width: '100%',
        },
        footer: {
            fontSize: 13,
            padding: '1rem',
            textAlign: 'center',
        },
        footerLink: {
            marginRight: theme.spacing(1),
            color: theme.palette.primary.main,
        }
    });

export default withStyles(styles)(Layout);