
export const APP_NAME = 'Chachoka';
export const APP_TITLE = 'Chachoka card game scorekeeper.';
export const APP_DESCRIPTION = 'A free online Chachoka card game scorekeeper. Play & Share games with friends!';
export const APP_URL = 'https://chachoka.com'

export const HASHID_SALT = 'AIzaSyC9Prs1uSBjJSOAuO9fqeVqj7g9xAQ-MqY';
export const COPYRIGHT = "© 2019 Chachoka. All rights reserved.";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyB6ZpUQsPmIKOPpqupEDabfW0UzJTnPZy0",
    authDomain: "chachoka.firebaseapp.com",
    databaseURL: "https://chachoka.firebaseio.com",
    projectId: "chachoka",
    storageBucket: "chachoka.appspot.com",
    messagingSenderId: "70533432542",
    appId: "1:70533432542:web:36e88bbe739306ae"
};