import React, { Component } from 'react';
import * as H from 'history';

import {
  Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography, Avatar, Chip
} from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import { deepOrange, deepPurple } from '@material-ui/core/colors';

import * as ROUTES from '../../constants/routes';

import { getShortPlayerName, getWinningPlayer, updateGameTotals } from '../../helpers/gamehelpers';

interface GameCardProps extends WithStyles<typeof styles> {
  game?: GameScore;
  history?: H.History;
  onShareGame?: (game: GameScore) => void;
  onDeleteGame?: (game: GameScore) => void;
}

class GameCard extends Component<GameCardProps, {}> {

  handleOpenClass = () => {
    const { game } = this.props;
    this.props.history.push(game.gameId);
  }

  handleNewGame = () => {
    this.props.history.push(ROUTES.NEW);
  }

  handleDeleteGame = (evt: any) => {
    evt.stopPropagation();

    const { game, onDeleteGame } = this.props;
    if (onDeleteGame) onDeleteGame(game);
  }

  handleShareGame = (evt: any) => {
    evt.stopPropagation();

    const { game, onShareGame } = this.props;
    if (onShareGame) onShareGame(game);
  }

  render() {
    const { classes, game } = this.props;

    if (!game) {
      return (
        <Card className={classes.card} onClick={this.handleNewGame}>
          <CardActionArea>
            <CardContent className={classes.newCard}>
              <AddIcon className={classes.newCardIcon} />
              <Typography gutterBottom variant="h5" component="h2">
                New Game
          </Typography>
              <Typography component="p">
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      );
    }

    // Calculate game totals
    updateGameTotals(game);

    const { index: winningPlayerIndex, name: winningPlayerName } = getWinningPlayer(game);
    
    return (
      <Card className={classes.card} onClick={this.handleOpenClass}>
        <CardActionArea>
          {/* <CardMedia
            className={classes.media}
            image={classroomSvg}
          /> */}
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {winningPlayerName && game.currentRound ? `${winningPlayerName} ${game.completed ? 'Won' : 'Leading'}` : 'New Game'}
            </Typography>
            <Typography component="p">
              {/* {game.description} */}
            </Typography>

            <div className={classes.avatarList}>
              {game.players.map((player, playerIndex) =>
                <Chip
                  key={playerIndex}
                  avatar={<Avatar>{getShortPlayerName(player.name)}</Avatar>}
                  label={game.totals && game.totals[playerIndex]}
                  className={classes.chip}
                  size="small"
                  color={playerIndex === winningPlayerIndex ? "secondary" : undefined}
                />
              )}
            </div>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          <Button size="small" color="primary" onClick={this.handleShareGame}>
            Share
          </Button>
          <Button size="small" color="secondary" onClick={this.handleDeleteGame} >
            Delete
          </Button>
        </CardActions>
      </Card>
    );
  }
}


const styles = (theme: Theme) =>
  createStyles({
    card: {
      minWidth: 300,
      height: 150,
    },
    media: {
      height: 100,
      backgroundSize: 'contain',
      opacity: 0.6,
    },
    newCard: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      height: 150,
      color: theme.palette.primary.main
    },
    newCardIcon: {
      fontSize: 48,
      margin: 8
    },
    avatarList: {
      display: 'flex',
      flexDirection: 'row',
    },
    avatar: {
      margin: theme.spacing(1) / 2,
      backgroundColor: deepOrange[500],
      height: 30,
      width: 30,
    },
    chip: {
      margin: theme.spacing(1) / 2,
    },
    cardActions: {
      justifyContent: 'space-between',
    },
  });


export default withStyles(styles)(GameCard);