import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import {
    Typography
} from '@material-ui/core';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';

interface FooterProps extends WithStyles<typeof styles> {
}

class Footer extends Component<FooterProps, {}> {

    render() {
        const { classes } = this.props;

        return (
            <Typography color="primary" className={classes.footer}>
                <Link to={ROUTES.INSTRUCTIONS} className={classes.footerLink}>
                    Rules/Instructions
                            </Link>
                <Link to={ROUTES.TERMS} className={classes.footerLink}>
                    Terms
                            </Link>
                <Link to={ROUTES.PRIVACY} className={classes.footerLink}>
                    Privacy
                            </Link>
                <br />
                {CONFIG.COPYRIGHT}
            </Typography>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        footer: {
            fontSize: 13,
            padding: theme.spacing(1),
            textAlign: 'center',
            marginTop: theme.spacing(4),
        },
        footerLink: {
            marginRight: theme.spacing(1),
            color: theme.palette.primary.main,
        }
    });

export default withStyles(styles)(Footer);