import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { withFirebase, FirebaseProps } from '../../components/Firebase';

import { AuthUserContext, withAuthorization } from '../../components/Session';
import Hashids from 'hashids';

import { Link } from 'react-router-dom';
import { ButtonBase, Card, CardContent } from '@material-ui/core';

import MobileStepper from '@material-ui/core/MobileStepper';
import { Grid, Container, Paper, Typography, Button, TextField } from '@material-ui/core';
import { createStyles, Theme, withTheme, WithTheme, withStyles, WithStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Layout from "../../containers/layout/Layout";

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import logo from '../../images/logo.svg';


import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { MenuItem, Popper } from '@material-ui/core';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import { getFullPlayerName } from '../../helpers/gamehelpers';

interface CreateProps extends FirebaseProps, RouteComponentProps, WithStyles<typeof styles>, WithTheme {
}

interface CreateState {
  activeStep?: number;
  numberOfPlayers?: number;
  selectedNumberOfPlayers?: number;
  selectedStartingPlayer?: number;
  nameValues?: Map<number, string>;

  suggestions?: Player[];
}

let players: Player[] = [];

class Create extends Component<CreateProps, CreateState> {
  private anchorEl: any[] = [];

  constructor(props: CreateProps) {
    super(props);
    this.state = {
      nameValues: new Map<number, string>(),
      activeStep: 0,
      selectedNumberOfPlayers: 2,

      suggestions: []
    }
  }

  componentDidMount() {
    this.props.firebase.onAuthUserListener((user: AuthUser) => {
      if (user) {
        const uid = user.uid;
        const userPlayers = user.players || {};

        // Convert to array
        players = Array.from(Object.values(userPlayers));
      }
    });
  }

  handleNameInput = (num: number) => (
    event: React.ChangeEvent<{}>,
    { newValue }: Autosuggest.ChangeEvent
  ) => {
    const { nameValues } = this.state;
    nameValues.set(num, newValue);
    this.setState({ nameValues });
  }

  handleNumberOfPlayers = (num: number) => {
    this.setState({
      selectedNumberOfPlayers: num
    })
  }

  handleStartingPlayer = (num: number) => {
    this.setState({
      selectedStartingPlayer: num
    })
  }

  isNextValid = () => {
    const { activeStep, nameValues, numberOfPlayers } = this.state;
    if (activeStep === 1) {
      // Check that all the players have names
      for (let i = 0; i < numberOfPlayers; i++) {
        const playerName = nameValues.get(i + 1);
        if (!playerName) return false;
      }
    }
    return true;
  }

  handleNext = () => {
    let nextState: CreateState = {};
    const step = this.state.activeStep;
    if (step === 0) {
      // Set number of players
      nextState.numberOfPlayers = this.state.selectedNumberOfPlayers;
    }
    nextState.activeStep = this.state.activeStep + 1;
    this.setState(nextState);
  }

  handleBack = () => {
    if (this.state.activeStep === 0) return;
    this.setState({
      activeStep: this.state.activeStep - 1
    })
  }

  private createLock = false;
  onCreateGame = async (event: any, authUser: AuthUser) => {
    event.preventDefault();
    if (this.createLock) return;
    this.createLock = true;

    const players: Player[] = [];
    this.state.nameValues.forEach(name => {
      players.push({
        name
      });
    })
    const startingPlayer = this.state.selectedStartingPlayer - 1; // Account for 1 index

    // Create a new game id
    const hashids = new Hashids(CONFIG.HASHID_SALT, 6);
    const newGameIdInt = await this.props.firebase.incrementCounter('game');
    const newGameId = hashids.encode(newGameIdInt);

    this.props.firebase.games().doc(newGameId).set({
      currentRound: 0,
      players: players,
      startingPlayer: startingPlayer,
      scores: [],
      createdBy: authUser.uid,
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
    }).then(() => {
      this.props.history.push(newGameId);

      // Save players to cache
      const userRecord = this.props.firebase.user(this.props.firebase.currentUser().uid);
      userRecord.get().then(snapshot => snapshot.data())
        .then((userData: any) => {
          if (!userData) userData = {};
          const currentPlayers = userData.players || {};
          players.forEach(player => {
            const sanitizedPlayerName = player.name.toLowerCase().trim();
            if (!currentPlayers.hasOwnProperty(sanitizedPlayerName)) {
              currentPlayers[sanitizedPlayerName] = player;
            }
          });
          userData.players = currentPlayers;
          userRecord.set(userData);
        });

      this.createLock = false;
    });
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }: any) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { classes, theme } = this.props;
    const { nameValues, activeStep, selectedNumberOfPlayers, selectedStartingPlayer, numberOfPlayers, suggestions } = this.state;

    const maxNumberOfSteps = 3;

    const numberOfPlayersStep = <React.Fragment>
      <Typography variant="h2" color="primary" className={classes.headingTextRow}>How many players?</Typography>
      <div className={classes.playersGrid}>
        <Grid container spacing={3}>
          {[2, 3, 4, 5, 6, 7].map(num =>
            <Grid item xs={6} key={'numplayer' + num}>
              <ButtonBase tabIndex={0} onClick={() => this.handleNumberOfPlayers(num)} className={classes.playersButton}>
                <Paper className={classNames(classes.playersPaper, {
                  [classes.playersPaperSelected]: selectedNumberOfPlayers === num
                })} elevation={4}>
                  <Typography variant="h4">{num}</Typography>
                </Paper>
              </ButtonBase>
            </Grid>
          )}
        </Grid>
      </div>
    </React.Fragment>

    let playerNamesStepFields: any[] = [];
    for (let i = 0; i < numberOfPlayers; i++) {
      const num = i + 1;
      playerNamesStepFields.push(<Grid item xs={12} key={'playername' + num}>
        {/* <TextField
          id="name"
          InputProps={{
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField
            }
          }}
          value={nameValues.get(num)}
          onChange={(e: any) => this.handleNameInput(num, e.target.value)}
          margin="normal"
          placeholder={`Player ${num}`}
          type="text"
          fullWidth
        /> */}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          inputProps={{
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField
            },
            id: 'player-autosuggest' + num,
            placeholder: `Player ${num}`,
            value: nameValues.get(num) || '',
            onChange: this.handleNameInput(num),
            inputRef: (node: HTMLInputElement | null) => {
              this.anchorEl[num] = node;
            },
            InputLabelProps: {
              shrink: true,
            },
          }}
          theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Popper anchorEl={this.anchorEl[num]} open={Boolean(options.children)}>
              <Paper
                square
                {...options.containerProps}
                style={{ width: this.anchorEl[num] ? this.anchorEl[num].clientWidth : undefined }}
              >
                {options.children}
              </Paper>
            </Popper>
          )}
        />
      </Grid>)
    }
    const playerNamesStep = <React.Fragment>
      <Typography variant="h2" color="primary" className={classes.headingTextRowSmall}>Player Names?</Typography>
      <Typography variant="h5" className={classes.headingTextRow}>List players in clock-wise order.</Typography>
      <div className={classes.playersGrid}>
        <Grid container spacing={3}>
          {playerNamesStepFields}
        </Grid>
      </div>

    </React.Fragment>

    let chooseStartingPlayerStepOptions: any[] = [];
    for (let i = 0; i < numberOfPlayers; i++) {
      const num = i + 1;
      chooseStartingPlayerStepOptions.push(
        <Grid item xs={12} key={'startingplayer' + num}>
          <ButtonBase tabIndex={0} onClick={() => this.handleStartingPlayer(num)} className={classes.playersButton}>
            <Paper className={classNames(classes.playersPaper, {
              [classes.playersPaperSelected]: selectedStartingPlayer === num
            })} elevation={4}>
              <Typography variant="h4">{nameValues.get(num)}</Typography>
            </Paper>
          </ButtonBase>
        </Grid>);
    }
    const chooseStartingPlayerStep = (authUser: AuthUser) => <React.Fragment>
      <Typography variant="h2" color="primary" className={classes.headingTextRow}>Starting Player?</Typography>
      <div className={classes.playersGrid}>
        <Grid container spacing={3} justify="center">
          {chooseStartingPlayerStepOptions}
          <Grid item>
            <Button variant="contained" type="submit" color="secondary" size="large" disabled={!selectedStartingPlayer} className={classes.startButton} onClick={event => this.onCreateGame(event, authUser)}>Begin Game</Button>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Layout>
            <Grid container spacing={4} className={classes.layout}>
              <Paper className={classes.paper} classes={{ root: classes.paperRoot }}>

                <div className={classes.paperInner}>
                  {activeStep === 0 && (numberOfPlayersStep)}
                  {activeStep === 1 && (playerNamesStep)}
                  {activeStep === 2 && (chooseStartingPlayerStep(authUser))}
                </div>

                <MobileStepper
                  variant="dots"
                  steps={maxNumberOfSteps}
                  position="static"
                  activeStep={activeStep}
                  className={classes.mobileStepper}
                  nextButton={
                    <Button size="small" onClick={this.handleNext} disabled={!this.isNextValid() || activeStep === maxNumberOfSteps - 1}>
                      Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                  }
                  backButton={
                    <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                      {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      Back
            </Button>
                  }
                />
              </Paper>
            </Grid>
          </Layout>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

function renderInputComponent(inputProps: any) {
  const { classes, inputRef = () => { }, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      margin="normal"
      type="text"
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(
  suggestion: Player,
  { query, isHighlighted }: Autosuggest.RenderSuggestionParams,
) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {index === 0 ? getFullPlayerName(part.text) : part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value: string) {
  const inputValue = (value || '').trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : players.filter(suggestion => {
      const keep =
        count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }

      return keep;
    });
}

function getSuggestionValue(suggestion: Player) {
  return suggestion.name;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: 0,
      left: 0,
      padding: theme.spacing(1),
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      flexGrow: 1,
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    logotype: {
      display: 'flex',
      alignItems: 'center',
    },
    logotypeText: {
      fontWeight: 500,
      color: 'white',
      marginLeft: theme.spacing(2),
    },
    logotypeIcon: {
      width: 70,
      marginRight: theme.spacing(2),
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      height: '100%',
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    paperRoot: {
      //      boxShadow: (theme as any).customShadows.widgetDark,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paperInner: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      minHeight: '400px',
    },
    headingTextRow: {
      marginBottom: theme.spacing(3),
    },
    headingTextRowSmall: {
      marginBottom: theme.spacing(1),
    },
    textRow: {
      marginBottom: theme.spacing(3),
    },
    textFieldUnderline: {
      "&:before": {
        borderBottomColor: theme.palette.primary.light
      },
      "&:after": {
        borderBottomColor: theme.palette.primary.main
      },
      "&:hover:before": {
        borderBottomColor: `${theme.palette.primary.light} !important`
      }
    },
    textField: {
      borderBottomColor: (theme.palette.background as any).light
    },
    mobileStepper: {
      width: '100%',
      maxWidth: 400,
      flexGrow: 1,
    },
    playersGrid: {
      flexGrow: 1
    },
    playersButton: {
      width: '100%',
      "&:focus > div": {
        outline: 'none',
        border: `1px solid ${theme.palette.secondary.main} !important`,
      },
    },
    playersPaper: {
      width: '100%',
      padding: theme.spacing(4),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      fontSize: 18,
      fontWeight: 500,
      border: '1px solid transparent',
      "&:hover": {
        border: `1px solid ${theme.palette.secondary.main} !important`,
      },
    },
    playersPaperSelected: {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
    startButton: {
      marginTop: theme.spacing(2),
    },

    // Auto suggest
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
  });


const condition = (authUser: firebase.User) => !!authUser;

export default withAuthorization(condition)(withTheme(withStyles(styles)(withFirebase(Create))));