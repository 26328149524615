import React, { Component } from 'react';

import Markdown from '../markdown';

class Instructions extends Component<{}, {}> {

    render() {
        return (
            <Markdown title={'Chachoka Rules'} md={
                `
## Winning

How to win? End the game with the least number of total points.

There are a total of 9 rounds in Chachoka, with each round having one goal and one winner.
The goal for each round is made up of some combination of 
* **Sets** (which are **3** cards of the same number)
* and/or **Runs** (which are **4** consecutive cards of the same suit).

The rounds are:

1. **1 Set**
2. **1 Run**
3. **2 Sets**
4. **1 Run & 1 Set**
5. **2 Runs**
6. **3 Sets**
7. **1 Run & 2 Sets**
8. **2 Runs & 1 Set**
9. **3 Runs**


The winner of each round is the person who gets rid of their hand of cards first, and ultimately gets **-10** points for that round.

Every other person immediately counts up their points for that round by counting the remaining cards in their hands with the following values for each card:

| Card       | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | J  | Q  | K  | Ace | Joker |
|------------|---|---|---|---|---|---|---|---|----|----|----|----|-----|-------|
| **Points** | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 10 | 10 | 10 | 20  | 20    |

The points from each round get added together to the points from the following round,
leading to a final tally at the end of the ninth round. 


## Cutting

Each round begins with one person shuffling two full decks of cards (including jokers).
The person sitting to the left of the shuffler/dealer then gets the chance to "cut" the deck and attempt to leave the right number of cards
needed to deal 11 cards for each player on the bottom of the stack. 

- 2 players = 22 or 23 cards
- 3 players = 33 or 34 cards
- 4 players = 44 or 45 cards
- 5 players = 55 or 56 cards

The shuffler/dealer will then deal out 11 cards face down for each player to put into their hand,
and one card next to the draw pile face up that starts the discard pile.

If the person who cut estimated accurately according to the number of players currently playing,
then they receive -10 pts. Each round, the cutting opportunity moves one person to the left, 
as the dealer is rotated clock-wise across the players. 


## Game Play

Each round starts with the person who cut drawing one card from the draw pile.
They are then able to lay down the goal for that round if they can,
and then at the end of their turn, whether they could lay anything down or not, to end their turn,
they have to place a card from their hand into the discard pile.

Once the card is placed in the discard pile, their turn is complete,
and they are not able to do anything else.
The next player to the cutter’s left (clock-wise) is then able to start their turn by drawing a card from the draw pile,
then they can play, and then they have to finish their turn by discarding.

This pattern continues with each player around the circle until one person gets rid of all of their cards
(including their final card going into the discard pile).

During the “play” section of the turn,
each player must first put down the goal for that round before being able to lay down any other cards on the table out of their hand.
Once they lay down the goal, during that same turn, they are able to play only on what others have already played.

On their next turn, they will then be able to only play sets out of their hand (no runs) and continue to play on others cards.
Playing on other people’s cards includes adding the same number to others’ sets,
or adding consecutive cards in succession to others’ runs.

The one exception to the rule of having to wait a whole round before playing new sets is if the player is able to completely put down their entire hand. 
This is known as "going out". 

In runs, Aces are either high or low, so there is no wrapping around (for example QKA2 is not allowed). 


## Jokers

Jokers are considered wild cards, which means they can be played in sets or runs to replace any card.
If a joker is played in a run and a player has in their hand the card that it is replacing in the run,
that player is able to switch the joker out for that card, and then use the joker as they please.

However, that switch can only happen on their turn, and that joker has to be used in that same turn.
This switch is not allowed if a joker is used in a set.


## Buys

If, before someone draws a card from the draw pile to start their turn, either that player or another player wants the card that was most recently laid down in the discard pile
(including at the start of the game, the card that was first dealt into the discard pile),
that person needs to alert whoever is playing next that they would like to buy that card.

Each player gets three buys to use in each round. 
Buys do not occur in a first come first served basis, but whoever's turn it is next gets priority over that buy.
Priority then moves in a counter-clockwise fashion (same as turn play) so each person ahead of you must say that they
do not want to buy it before you can buy it.

Upon buying a card, you not only pick up the bought card out of the discard pile,
but you also have to draw a “tax” card. If you are buying right before your own turn,
you additionally need to draw a card from the draw pile in order to start your turn. 


## Enjoy the Games!

                `
            } />
        );
    }
}

export default Instructions;