import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import { Link } from 'react-router-dom';
import { Container, Paper, Typography, Button } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import Layout from '../../containers/layout/Layout';

import logo from '../../images/logo.svg';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';

interface ErrorProps extends WithStyles<typeof styles> {
}

class Error extends Component<ErrorProps, {}> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { classes } = this.props;

    return (<Layout>
      <Container className={classes.layout}>
        <Paper classes={{ root: classes.paperRoot }}>
          <Typography variant="h1" color="primary" className={classnames(classes.textRow, classes.errorCode)}>404</Typography>
          <Typography variant="h5" color="primary" className={classes.textRow}>Oops. Looks like the page you're looking for no longer exists</Typography>
          <Typography variant="h6" color="textSecondary" className={classnames(classes.textRow, classes.safetyText)}>But we're here to bring you back to safety</Typography>
          <Button variant="contained" color="primary" component={props => <Link {...props} to={ROUTES.LANDING} />} size="large" className={classes.backButton}>Back to Home</Button>
        </Paper>
      </Container>
    </Layout>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    logotype: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(12),
      [theme.breakpoints.down("sm")]: {
        display: 'none',
      }
    },
    logotypeText: {
      fontWeight: 500,
      color: 'white',
      marginLeft: theme.spacing(2),
    },
    logotypeIcon: {
      width: 70,
      marginRight: theme.spacing(2),
    },
    paperRoot: {
      //boxShadow: (theme as any).customShadows.widgetDark,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      maxWidth: 500,
    },
    textRow: {
      marginBottom: theme.spacing(10),
      textAlign: 'center',
    },
    errorCode: {
      fontSize: 148,
      fontWeight: 600,
    },
    safetyText: {
      fontWeight: 300,
      color: theme.palette.text.hint,
    },
    backButton: {
      boxShadow: (theme as any).customShadows.widget,
      textTransform: 'none',
      fontSize: 22,
    }
  });


export default withStyles(styles)(Error);