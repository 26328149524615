import React from 'react';

import AuthUserContext from './context';
import { withFirebase, FirebaseProps } from '../Firebase';

interface withAuthenticationState {
    authUser?: AuthUser;
}

const withAuthentication = (Comp: React.ComponentClass | React.FC) => {
  class WithAuthentication extends React.Component<FirebaseProps, withAuthenticationState> {

    private listener: firebase.Unsubscribe;
    private profileListener: firebase.Unsubscribe;

    constructor(props: FirebaseProps) {
      super(props);

      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser')),
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        (authUser: AuthUser) => {
          localStorage.setItem('authUser', JSON.stringify(authUser));
          this.setState({ authUser });
        },
        () => {
          localStorage.removeItem('authUser');
          this.setState({ authUser: null });
        },
      );
      this.profileListener = this.props.firebase.onUserProfileChanged(
        (authUser: AuthUser) => {
          this.setState({ authUser });
        }
      )
    }

    componentWillUnmount() {
      this.listener();
      this.profileListener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Comp {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;