import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import { withFirebase, FirebaseProps } from '../../components/Firebase';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade
} from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import classnames from "classnames";

import logo from '../../images/logo.svg';
import google from "../../images/google.svg";
import microsoft from "../../images/microsoft.svg";
import facebook from "../../images/facebook.svg";

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';

import Footer from '../../containers/layout/Footer';

interface LoginProps extends RouteComponentProps, FirebaseProps, WithStyles<typeof styles> {
}

interface LoginState {
  activeTabId: number;
  isLoading?: boolean;
  error?: boolean;

  nameValue?: string;
  loginValue?: string;
  passwordValue?: string;
}

class Login extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      activeTabId: 0,
      nameValue: '',
      loginValue: '',
      passwordValue: ''
    }
  }

  handleLoginInput = (e: any) => {
  }

  handlePasswordInput = (e: any) => {
  }

  handleNameInput = (e: any) => {
  }
  
  handleTabChange = (e: any) => {
  }

  handleLoginButtonClick = (e: any) => {
  }

  handleAnonymousSignInClick = () => {
    const referrer = this.props.location.state && this.props.location.state.from;
    this.props.firebase.doSignInAnonymous()
      .then(() => {
        this.props.history.push(referrer || ROUTES.LANDING);
      });
  }

  handleGoogleSignInClick = () => {
    const referrer = this.props.location.state && this.props.location.state.from;
    this.props.firebase.doSignInWithGoogle()
      .then(() => {
        this.props.history.push(referrer || ROUTES.LANDING);
      });
  }

  handleMicrosoftSignInClick = () => {
    const referrer = this.props.location.state && this.props.location.state.from;
    this.props.firebase.doSignInWithMicrosoft()
      .then(() => {
        this.props.history.push(referrer || ROUTES.LANDING);
      });
  }

  handleFacebookSignInClick = () => {
    const referrer = this.props.location.state && this.props.location.state.from;
    this.props.firebase.doSignInWithFacebook()
      .then(() => {
        this.props.history.push(referrer || ROUTES.LANDING);
      });
  }

  render() {
    const { classes } = this.props;
    const { activeTabId, isLoading, error, nameValue, loginValue, passwordValue } = this.state;

    return (<Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>{CONFIG.APP_NAME}</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          {/* <Tabs
            value={activeTabId}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
            <Tab label="New User" classes={{ root: classes.tab }} />
          </Tabs> */}
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                Welcome to {CONFIG.APP_NAME}
              </Typography>
              <Typography variant="h6" className={classes.subGreeting}>
                Choose one of the following
              </Typography>
              <Button size="large" className={classes.googleButton} onClick={this.handleGoogleSignInClick}>
                <img src={google} alt="google" className={classes.googleIcon} />
                &nbsp;Continue with Google
              </Button>
              <Button size="large" className={classes.googleButton} onClick={this.handleMicrosoftSignInClick}>
                <img src={microsoft} alt="microsoft" className={classes.googleIcon} />
                &nbsp;Continue with Microsoft
              </Button>
              <Button size="large" className={classes.googleButton} onClick={this.handleFacebookSignInClick}>
                <img src={facebook} alt="facebook" className={classes.googleIcon} />
                &nbsp;Continue with Facebook
              </Button>
              <Button size="large" className={classes.googleButton} onClick={this.handleAnonymousSignInClick}>
                &nbsp;Continue Anonymously
              </Button>
            </React.Fragment>
          )}
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                Welcome!
              </Typography>
              <Typography variant="h2" className={classes.subGreeting}>
                Create your account
              </Typography>
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
              <TextField
                id="name"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField
                  }
                }}
                value={nameValue}
                onChange={this.handleNameInput}
                margin="normal"
                placeholder="Full Name"
                type="email"
                fullWidth
              />
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField
                  }
                }}
                value={loginValue}
                onChange={this.handleLoginInput}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField
                  }
                }}
                value={passwordValue}
                onChange={this.handlePasswordInput}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                    <Button
                      onClick={this.handleLoginButtonClick}
                      disabled={
                        loginValue.length === 0 ||
                        passwordValue.length === 0 ||
                        nameValue.length === 0
                      }
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.createAccountButton}
                    >
                      Create your account
                  </Button>
                  )}
              </div>
              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}>or</Typography>
                <div className={classes.formDivider} />
              </div>
              <Button
                size="large"
                className={classnames(
                  classes.googleButton,
                  classes.googleButtonCreating
                )}
              >
                {/* <img src={google} alt="google" className={classes.googleIcon} /> */}
                &nbsp;Sign in with Google
              </Button>
            </React.Fragment>
          )}
        </div>
        <Footer />
      </div>
    </Grid>
    );
  }
}


const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 0,
      left: 0
    },
    logotypeContainer: {
      backgroundColor: theme.palette.primary.main,
      width: "60%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%"
      },
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    },
    logotypeImage: {
      width: 165,
      marginBottom: theme.spacing(4),
    },
    logotypeText: {
      color: "white",
      fontWeight: 500,
      fontSize: 84,
      [theme.breakpoints.down("md")]: {
        fontSize: 48
      }
    },
    formContainer: {
      width: "40%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%"
      }
    },
    form: {
      width: 320
    },
    tab: {
      fontWeight: 400,
      fontSize: 18
    },
    greeting: {
      fontWeight: 500,
      textAlign: "center",
      marginTop: theme.spacing(4),
    },
    subGreeting: {
      fontWeight: 500,
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
    googleButton: {
      marginTop: theme.spacing(4),
      boxShadow: (theme as any).customShadows.widget,
      backgroundColor: "white",
      width: "100%",
      textTransform: "none"
    },
    googleButtonCreating: {
      marginTop: 0
    },
    googleIcon: {
      width: 30,
      marginRight: theme.spacing(2),
    },
    creatingButtonContainer: {
      marginTop: theme.spacing(2.5),
      height: 46,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    createAccountButton: {
      height: 46,
      textTransform: "none"
    },
    formDividerContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      display: "flex",
      alignItems: "center"
    },
    formDividerWord: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    formDivider: {
      flexGrow: 1,
      height: 1,
      backgroundColor: theme.palette.text.hint + "40"
    },
    errorMessage: {
      textAlign: "center"
    },
    textFieldUnderline: {
      "&:before": {
        borderBottomColor: theme.palette.primary.light
      },
      "&:after": {
        borderBottomColor: theme.palette.primary.main
      },
      "&:hover:before": {
        borderBottomColor: `${theme.palette.primary.light} !important`
      }
    },
    textField: {
      borderBottomColor: (theme.palette.background as any).light
    },
    formButtons: {
      width: "100%",
      marginTop: theme.spacing(4),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    forgetButton: {
      textTransform: "none",
      fontWeight: 400
    },
    loginLoader: {
      marginLeft: theme.spacing(4),
    },
    copyright: {
      position: "absolute",
      bottom: theme.spacing(2),
    }
  });


export default withStyles(styles)(withFirebase(Login));