


export const calculateRoundScore = (score: Score) => {
    let roundScore = score.roundScore;
    if (score.isWonCut) roundScore -= 10;
    if (score.isWonRound) roundScore -= 10;
    return roundScore;
}

export const updateGameTotals = (game: GameScore) => {
    game.totals = [];
    for (let i = 0; i < game.players.length; i++) {
        let cummulativeScore = 0;
        for (let j = 0; j < 9; j++) {
            const round = game.scores[j];
            if (round && round.scores[i]) {
                const roundScore = calculateRoundScore(round.scores[i]);
                if (roundScore) {
                    cummulativeScore += Number(roundScore);
                    round.scores[i].cummulativeScore = cummulativeScore;
                }
            }
        }
        // Save totals
        game.totals[i] = cummulativeScore;
    }
}

export const getWinningPlayer = (game: GameScore): { index: number, name: string } => {
    if (typeof (game.winner) !== "undefined") return {
        index: game.winner,
        name: getWinningPlayerName(game, game.winner)
    };
    if (!game.totals) return undefined;
    const winningIndex = indexOfMin(game.totals);

    return {
        index: winningIndex,
        name: getWinningPlayerName(game, winningIndex)
    }
}

export const getWinningPlayerName = (game: GameScore, winningIndex: number) => {
    const winningFullName = (typeof winningIndex !== "undefined") && winningIndex !== -1
        && game.players[winningIndex].name;
    return winningFullName.split(' ')[0];
}

const indexOfMin = (arr: number[]) => {
    if (arr.length === 0) {
        return -1;
    }
    let min = arr[0];
    let minIndex = 0;
    for (let i = 1; i < arr.length; i++) {
        if (arr[i] < min) {
            minIndex = i;
            min = arr[i];
        }
    }
    return minIndex;
}

const toTitleCase = (text: string) => {
    return text.toLowerCase()
        .split(' ')
        .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}

export const getFullPlayerName = (name: string) => {
    return toTitleCase(name);
}

export const getShortPlayerName = (name: string) => {
    return `${name.slice(0, 1).toUpperCase()}${name.slice(1, 2)}`;
}

export const getGameShareUrl = (game: GameScore) => {
    return `https://${window.location.hostname}/${game.gameId}`;
}