import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';
import Helmet from './Helmet';
import * as serviceWorker from './serviceWorker';

import CssBaseline from '@material-ui/core/CssBaseline';

import Firebase, { FirebaseContext } from './components/Firebase';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes, { overrides } from './themes';
const theme = createMuiTheme({ ...themes.default, ...overrides });

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <MuiThemeProvider theme={theme}>
      <React.Fragment>
        <Helmet />
        <CssBaseline />
        <App />
      </React.Fragment>
    </MuiThemeProvider>
  </FirebaseContext.Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
