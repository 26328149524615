export const LANDING = '/';
export const LOGIN = '/login';

export const PRIVACY = '/privacy';
export const TERMS = '/terms';
export const INSTRUCTIONS = '/rules';

// Private routes

export const NEW = '/new';
export const GAMES = '/games';
export const GAME = '/:gid';

