import React, { Component } from 'react';

import ReactMarkdown from 'react-markdown'

import { Container, Grid, Paper, Typography, Button } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import classnames from 'classnames';

import Layout from '../containers/layout/Layout';

import * as CONFIG from '../constants/config';
import * as ROUTES from '../constants/routes';

interface MarkdownProps extends WithStyles<typeof styles> {
    title?: string;
    md: string;
}

class Markdown extends Component<MarkdownProps, {}> {

    render() {
        const { classes, title, md } = this.props;

        return (<Layout>
            <div className={classes.layout}>
                <Paper className={classes.paper}>
                    {title ? <Typography variant="h3" color="primary" className={classnames(classes.textRow, classes.errorCode)}>{title}</Typography> : undefined}
                    <ReactMarkdown source={md} />
                </Paper>
            </div>
        </Layout>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
        },
        layout: {
            width: 'auto',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
                width: '80%',
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            overflowX: 'hidden',
            [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            },
        },
        textRow: {
            marginBottom: theme.spacing(10),
            textAlign: 'center',
        },
        errorCode: {
            fontSize: 40,
            fontWeight: 600,
        },
        safetyText: {
            fontWeight: 300,
            color: theme.palette.text.hint,
        }
    });


export default withStyles(styles)(Markdown);