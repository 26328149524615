import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Container, Paper, Typography, Button } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import classnames from 'classnames';

import Layout from '../../containers/layout/Layout';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';

interface TermsProps extends WithStyles<typeof styles> {
}

class Terms extends Component<TermsProps, {}> {

    render() {
        const { classes } = this.props;

        return (<Layout>
            <Container className={classes.layout}>
                <Paper classes={{ root: classes.paperRoot }}>
                    <Typography variant="h3" color="primary" className={classnames(classes.textRow, classes.errorCode)}>Terms &amp; Conditions</Typography>
                    <Typography variant="h5" color="primary" className={classes.textRow}>


                    </Typography>
                    <Typography variant="h6" color="textSecondary" className={classnames(classes.textRow, classes.safetyText)}>

                        
                    </Typography>
                </Paper>
            </Container>
        </Layout>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        layout: {
            width: 'auto',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
                width: 600,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paperRoot: {
            //boxShadow: (theme as any).customShadows.widgetDark,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
        },
        textRow: {
            marginBottom: theme.spacing(10),
            textAlign: 'center',
        },
        errorCode: {
            fontSize: 40,
            fontWeight: 600,
        },
        safetyText: {
            fontWeight: 300,
            color: theme.palette.text.hint,
        }
    });


export default withStyles(styles)(Terms);