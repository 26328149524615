
import React from 'react';

import { Helmet } from "react-helmet";

import * as CONFIG from './constants/config';

const AppHelmet: React.FC = () => {

    const app_name = CONFIG.APP_NAME;
    const title = CONFIG.APP_TITLE;
    const description = CONFIG.APP_DESCRIPTION;

    const site_url = CONFIG.APP_URL;
    const lang = 'en';
    const image = '/og-image.png';

    const themeColor = '#00B894';
    const helmetDir = '/icons';

    return (
        <Helmet>
            <html lang={lang} />
            <title>{app_name}</title>
            <meta name="description" content={description} />

            <link rel="shortcut icon" href={`${helmetDir}/favicon.ico`} />
            <link rel="icon" type="image/png" sizes="16x16" href={`${helmetDir}/favicon-16x16.png`} />
            <link rel="icon" type="image/png" sizes="32x32" href={`${helmetDir}/favicon-32x32.png`} />

            <meta name="mobile-web-app-capable" content={'yes'} />
            <meta name="theme-color" content={themeColor} />
            <meta name="application-name" content={app_name} />

            <link rel="apple-touch-icon" sizes="57x57" href={`${helmetDir}/apple-touch-icon-57x57.png`} />
            <link rel="apple-touch-icon" sizes="60x60" href={`${helmetDir}/apple-touch-icon-60x60.png`} />
            <link rel="apple-touch-icon" sizes="72x72" href={`${helmetDir}/apple-touch-icon-72x72.png`} />
            <link rel="apple-touch-icon" sizes="76x76" href={`${helmetDir}/apple-touch-icon-76x76.png`} />
            <link rel="apple-touch-icon" sizes="114x114" href={`${helmetDir}/apple-touch-icon-114x114.png`} />
            <link rel="apple-touch-icon" sizes="120x120" href={`${helmetDir}/apple-touch-icon-120x120.png`} />
            <link rel="apple-touch-icon" sizes="144x144" href={`${helmetDir}/apple-touch-icon-144x144.png`} />
            <link rel="apple-touch-icon" sizes="152x152" href={`${helmetDir}/apple-touch-icon-152x152.png`} />
            <link rel="apple-touch-icon" sizes="167x167" href={`${helmetDir}/apple-touch-icon-167x167.png`} />
            <link rel="apple-touch-icon" sizes="180x180" href={`${helmetDir}/apple-touch-icon-180x180.png`} />
            <link rel="apple-touch-icon" sizes="1024x1024" href={`${helmetDir}/apple-touch-icon-1024x1024.png`} />

            <meta name="apple-mobile-web-app-capable" content={'yes'} />
            <meta name="apple-mobile-web-app-status-bar-style" content={"black-translucent"} />
            <meta name="apple-mobile-web-app-title" content={app_name} />

            <link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)" href={`${helmetDir}/apple-touch-startup-image-320x460.png`} />
            <link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)" href={`${helmetDir}/apple-touch-startup-image-640x920.png`} />
            <link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" href={`${helmetDir}/apple-touch-startup-image-640x1096.png`} />
            <link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" href={`${helmetDir}/apple-touch-startup-image-750x1294.png`} />
            <link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)" href={`${helmetDir}/apple-touch-startup-image-1182x2208.png`} />
            <link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href={`${helmetDir}/apple-touch-startup-image-1242x2148.png`} />
            <link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)" href={`${helmetDir}/apple-touch-startup-image-748x1024.png`} />
            <link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)" href={`${helmetDir}/apple-touch-startup-image-768x1004.png`} />
            <link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)" href={`${helmetDir}/apple-touch-startup-image-1496x2048.png`} />
            <link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href={`${helmetDir}/apple-touch-startup-image-1536x2008.png`} />
            <link rel="icon" type="image/png" sizes="228x228" href={`${helmetDir}/coast-228x228.png`} />

            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content={`${helmetDir}/mstile-144x144.png`} />
            <meta name="msapplication-config" content={`${helmetDir}/browserconfig.xml`} />
            <link rel="yandex-tableau-widget" href={`${helmetDir}/yandex-browser-manifest.json`} />


            <meta property="og:site_name" content={app_name} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:url" content={site_url} />
            <meta property="og:image" content={image} />
        </Helmet>
    );
}

export default AppHelmet;