import React, { Component } from 'react';

import { Grid, Paper, Typography, Button, TextField } from '@material-ui/core';
import { createStyles, Theme, withTheme, WithTheme, withStyles, WithStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Table, TableCell, TableHead, TableRow, TableBody, Tooltip, Badge, IconButton, Hidden } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import Zoom from '@material-ui/core/Zoom';

import { updateGameTotals, getWinningPlayer, calculateRoundScore, getFullPlayerName, getShortPlayerName } from '../../helpers/gamehelpers';

interface ScoreSheetProps extends WithStyles<typeof styles> {
  game: GameScore;
  onClose?: () => void;
}

interface ScoreSheetState {
}

export const ROUND_NAMES = ['1S', '1R', '2S', '1R1S', '2R', '3S', '1R2S', '2R1S', '3R'];
export const FULL_ROUND_NAMES = ['One Set', 'One Run', 'Two Sets', 'One Run & One Set', 'Two Runs', 'Three Sets', 'One Run & Two Sets', 'Two Runs & One Set', 'Three Runs'];

class ScoreSheet extends Component<ScoreSheetProps, ScoreSheetState> {
  constructor(props: ScoreSheetProps) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { classes, game } = this.props;

    const startingPlayer = game.startingPlayer;
    const numOfPlayers = game.players.length;
    const currentRound = game.currentRound;

    // Compute cummulative scores
    updateGameTotals(game);

    let ultimateWinningPlayer: number;
    if (game.completed) {  
      const {index: ultimateWinningPlayer} = getWinningPlayer(game);
    }

    let rows: any = [];
    for (let i = 0; i < 9; i++) { // 9 rounds
      const round: Score[] = (game.scores[i] && game.scores[i].scores) || [];
      const roundName = ROUND_NAMES[i];
      const fullRoundName = FULL_ROUND_NAMES[i];

      rows.push(
        <StyledTableRow key={i} className={classNames({
          [classes.currentRound]: currentRound === i
        })}>
          <StyledTableCell component="th" scope="row" className={classes.roundName}>
            <Tooltip disableFocusListener title={fullRoundName} TransitionComponent={Zoom} placement="right">
              {false ? <CurrentBadge className={classes.currentRoundBadge} color="secondary" badgeContent='Now'>
                <span>{roundName}</span>
              </CurrentBadge> : <span>{roundName}</span>}
            </Tooltip>
          </StyledTableCell>
          {game.players.map((player, playerIndex) =>
            <StyledTableCell align="center" className={classNames({
              [classes.startingPlayer]: ((i + startingPlayer) % numOfPlayers) == playerIndex,
              [classes.playerWonRound]: round[playerIndex] && round[playerIndex].isWonRound
            })}>
              {round[playerIndex] ?
                <div className={classes.playerScore}>
                  <span className={classes.playerRoundScore}>
                    {round[playerIndex].isWonCut ? <CutBadge color="secondary" badgeContent={'C'}>
                      {calculateRoundScore(round[playerIndex])}
                    </CutBadge> : calculateRoundScore(round[playerIndex])}
                  </span>
                  <span className={classes.playerScoreDivider}>/</span>
                  <span className={classes.cummulativeScore}>{round[playerIndex].cummulativeScore}</span>
                </div>
                : undefined}
            </StyledTableCell>
          )}
        </StyledTableRow>);
    }

    return (
      <Paper className={classes.paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.buttonCell}>
                {this.props.onClose && (
                  <IconButton color="inherit" onClick={this.props.onClose} aria-label="Close">
                    <CloseIcon />
                  </IconButton>
                )}
              </StyledTableCell>
              {game.players.map((player, playerIndex) =>
                <StyledTableCell align="center">
                  <Hidden smUp>
                    <Tooltip disableFocusListener title={getFullPlayerName(player.name)} TransitionComponent={Zoom} placement="bottom">
                      <span>{getShortPlayerName(player.name)}</span>
                    </Tooltip>
                  </Hidden>
                  <Hidden xsDown>
                    {getFullPlayerName(player.name)}
                  </Hidden>
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
            <TableRow className={classes.totalsRow}>
              <StyledTableCell className={classes.totalsCell}>Total</StyledTableCell>
              {game.totals.map((total, playerIndex) =>
                <StyledTableCell align="center" className={classNames(classes.totalsCell, {
                  [classes.totalsCellWinner]: ultimateWinningPlayer === playerIndex
                })}>{total}</StyledTableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 14,
    },
    body: {
      fontSize: 14,
      padding: 1,
      '&:last-child': {
        paddingRight: 1,
      },
      '&:not(:first-child)': {
        borderLeft: `1px solid ${(theme.palette.background as any).light}`,
      },
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {

      },
    },
  }),
)(TableRow);

const CurrentBadge = withStyles((theme: Theme) => ({
  badge: {
    top: -theme.spacing(1) / 2,
    left: 0,
    right: 'auto',
  },
}))(Badge);

const CutBadge = withStyles((theme: Theme) => ({
  badge: {
    top: '50%',
    left: theme.spacing(1),
    fontSize: 10,
    height: 15,
    minWidth: 15,
    borderRadius: '100%',
    padding: 0,
  },
}))(Badge);


const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 250,
    },
    totalsRow: {
      backgroundColor: (theme.palette.background as any).light,
      color: theme.palette.common.white,
      fontWeight: 900,
    },
    totalsCell: {
      fontWeight: 900,
    },
    totalsCellWinner: {
      color: theme.palette.primary.main,
    },
    buttonCell: {
      padding: 0,
    },
    currentRound: {
      border: `2px solid ${theme.palette.secondary.light}`,
      // backgroundColor: theme.palette.secondary.light,
      // color: 'white',
    },
    currentRoundBadge: {
    },
    roundName: {
      fontWeight: 500,
      paddingLeft: 3,
      //cursor: 'cell',
    },
    playerScore: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    playerRoundScore: {
      fontWeight: 100,
      fontSize: 12,
    },
    playerScoreDivider: {
      fontSize: 30,
      fontWeight: 100,
      color: theme.palette.text.hint,
      marginLeft: theme.spacing(1) / 2,
      marginRight: theme.spacing(1) / 2,
    },
    cummulativeScore: {
      fontSize: 16,
      fontWeight: 500,
    },
    startingPlayer: {
      backgroundColor: (theme.palette.background as any).light,
    },
    playerWonCut: {
      position: 'absolute',
      top: -theme.spacing(2),
      color: (theme.palette as any).secondary.dark,
    },
    playerWonRound: {
      backgroundColor: (theme.palette as any).success.light,
      //borderBottom: `4px solid ${(theme.palette as any).success.light}`,
    }
  });


export default withStyles(styles)(ScoreSheet);