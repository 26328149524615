import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import { Link } from 'react-router-dom';
import { Container, Paper, Typography, Button } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { withFirebase, FirebaseProps } from '../../components/Firebase';

import { AuthUserContext } from '../../components/Session';

import Games from '../../containers/games/Games';
import Layout from '../../containers/layout/Layout';

import logo from '../../images/logo.svg';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';

interface HomeProps extends FirebaseProps, RouteComponentProps, WithStyles<typeof styles> {
}

class Home extends Component<HomeProps, {}> {
  constructor(props: HomeProps) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !authUser ?
            <Layout>
              <Container className={classes.layout}>
                <Paper className={classes.paper}>
                  <div className={classes.paperInner}>
                    <img src={logo} alt="logo" className={classes.logotypeImage} />
                    <Typography className={classes.logotypeText}>Welcome to {CONFIG.APP_NAME}</Typography>
                    <Typography className={classes.subheadingText}> </Typography>
                    <Button variant="contained" color="secondary" component={props => <Link {...props} to={ROUTES.NEW} />} size="large" className={classes.getstartedButton}>New Game</Button>
                  </div>
                </Paper>
              </Container>
            </Layout> :
            <Games />
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.primary.main,
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 0,
      left: 0
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      flexGrow: 1,
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    paperInner: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      minHeight: '400px',
      textAlign: 'center',
    },
    logotypeContainer: {
      width: "60%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    logotypeImage: {
      width: 165,
      marginBottom: theme.spacing(4),
    },
    logotypeText: {
      color: theme.palette.text.hint,
      fontWeight: 500,
      fontSize: 50,
      [theme.breakpoints.down("md")]: {
        fontSize: 30
      }
    },
    subheadingText: {
      color: "white",
      fontWeight: 500,
      fontSize: 20,
      textAlign: 'center',
      padding: '2rem'
    },
    getstartedButton: {
      fontSize: 25
    }
  });


export default withFirebase(withStyles(styles)(Home));