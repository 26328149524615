import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import AuthUserContext from './context';
import { withFirebase, FirebaseProps } from '../Firebase';

import * as ROUTES from '../../constants/routes';

interface WithFirebaseProps extends FirebaseProps, RouteComponentProps {
}

const withAuthorization = (condition: (authUser: firebase.User) => boolean) =>
  <P extends object>(Comp: React.ComponentType<P>) => {
  class withAuthorization extends React.Component<P & WithFirebaseProps> {

    private listener: firebase.Unsubscribe;

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (!condition(authUser)) {
          this.props.history.push(ROUTES.LOGIN);
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser) ? <Comp {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(withFirebase(withAuthorization));
};

export default withAuthorization;