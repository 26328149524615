import app, { database } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import * as CONFIG from '../../constants/config';
import { EventEmitter } from "events";

class Firebase {

  public fieldValue: any;

  public auth: app.auth.Auth;
  private db: app.firestore.Firestore;

  private googleProvider: app.auth.GoogleAuthProvider;
  private facebookProvider: app.auth.FacebookAuthProvider;
  private twitterProvider: app.auth.TwitterAuthProvider;
  private microsoftProvider: app.auth.AuthProvider;

  private emitter: EventEmitter;

  constructor() {
    app.initializeApp(CONFIG.FIREBASE_CONFIG);

    /* Helper */
    this.fieldValue = app.firestore.FieldValue;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();

    /* Social Sign In Method Provider */
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    //this.facebookProvider.addScope('user_friends');
    this.twitterProvider = new app.auth.TwitterAuthProvider();
    this.microsoftProvider = new app.auth.OAuthProvider('microsoft.com');

    this.emitter = new EventEmitter();

    // Enable offline persistence
    this.db.enablePersistence({
      experimentalTabSynchronization:true
    })
  }

  // doCreateUserWithEmailAndPassword = (email, password) =>
  //   this.auth.createUserWithEmailAndPassword(email, password);

  // doSignInWithEmailAndPassword = (email, password) =>
  //   this.auth.signInWithEmailAndPassword(email, password);

  private doSignIn = () => {
  }

  doSignInAnonymous = () => 
    this.auth.signInAnonymously()
      .then(this.doSignIn);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider)
      .then(this.doSignIn);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider)
      .then(this.doSignIn);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider)
      .then(this.doSignIn);

  doSignInWithMicrosoft = () =>
    this.auth.signInWithPopup(this.microsoftProvider)
      .then(this.doSignIn);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password: string) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next: (user: AuthUser) => void, fallback?: () => void) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            // default empty roles
            if (dbUser && !dbUser.roles) {
              dbUser.roles = {};
            }

            const isAnonymous = authUser.isAnonymous;

            // merge auth and db user
            const mergedAuthUser: AuthUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              displayName: this.getDisplayName(authUser),
              photoURL: authUser.photoURL,
              isAnonymous: isAnonymous,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(mergedAuthUser);
          });
      } else {
        fallback();
      }
    });
  
  onUserProfileChanged = (next: (user: AuthUser) => void) => {
    this.emitter.addListener('profileChanged', next);
    return () => {
      this.emitter.removeListener('profileChanged', next);
    }
  }

  updateDisplayName = async (displayName: string) => {
    await this.currentUser().updateProfile({
      displayName
    });
    this.emitter.emit('profileChanged', this.currentUser());
  }

  getDisplayName = (user: DisplayUser) => {
    if (!user.displayName && user.isAnonymous) return 'Anonymous';
    return user.displayName;
  }

  // *** Counter API ***

  incrementCounter = (cid: string) => {
    const counterRef = this.db.collection('counters').doc(cid);
    return counterRef.get()
      .then(snapShot => {
        const currentCount = snapShot.exists ? snapShot.data().count : 0;
        counterRef.set({
          count: Number(currentCount) + 1
        });
        return currentCount;
      })
  }

  functions = () => app.functions();

  // *** User API ***

  currentUser = () => this.auth.currentUser;

  user = (uid: string) => this.db.collection('users').doc(`${uid}`);

  users = () => this.db.collection('users');

  // *** Message API ***

  message = (mid: string) => this.db.collection('messages').doc(`${mid}`);

  messages = () => this.db.collection('messages');

  // *** Game API ***

  game = (cid: string) => this.db.collection('games').doc(`${cid}`);

  games = () => this.db.collection('games');


  // *** Status API ***

  status = (uid: string) => this.db.collection('status').doc(uid);

  // *** Signaling API ***

  signaling = (channelId: string) => this.db.collection('signaling').doc(channelId).collection('signals');


}


export default Firebase;