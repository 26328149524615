import React from 'react';

import Home from './containers/home/Home';
import Create from './containers/create/Create';
import Game from './containers/game/Game';
import Error from './containers/error/Error';
import Instructions from './pages/instructions/Instructions';
import Terms from './pages/terms/Terms';
import Privacy from './pages/privacy/Privacy';

import Login from './containers/login/Login';

import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import * as ROUTES from './constants/routes';

import { withAuthentication } from './components/Session';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path={ROUTES.LANDING} component={Home} />

          <Route path={ROUTES.NEW} component={Create} />

          <Route path={ROUTES.LOGIN} component={Login} />

          <Route path={ROUTES.INSTRUCTIONS} component={Instructions} />
          <Route path={ROUTES.TERMS} component={Terms} />
          <Route path={ROUTES.PRIVACY} component={Privacy} />

          <Route path={ROUTES.GAME} component={Game} />
          <Route component={Error} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default withAuthentication(App);
