import React, { Component } from 'react';
import { withFirebase, FirebaseProps } from '../Firebase';
import classNames from 'classnames';

import { Menu, MenuItem, IconButton, Avatar } from '@material-ui/core/';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

interface UserAvatarProps extends FirebaseProps, WithStyles<typeof styles> {
  authUser: AuthUser;
  className?: string;
  avatarClassName?: string;

  avatarId?: string;
  connected?: boolean | undefined;
}

interface UserAvatarState {

  anchorEl: null | HTMLElement;
  mobileMoreAnchorEl: null | HTMLElement;

}

class UserAvatar extends Component<UserAvatarProps, UserAvatarState> {

  constructor(props: UserAvatarProps) {
    super(props);

    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
    }
  }


  handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  }

  handleSignOutClick = () => {
    this.handleMenuClose();
    this.props.firebase.doSignOut();
  }

  render() {
    const { classes, authUser, className, avatarClassName, connected, avatarId } = this.props;
    const { anchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleSignOutClick}>Sign out</MenuItem>
      </Menu>
    );

    return (
      <React.Fragment>
        <IconButton className={classNames(className, classes.avatarButton)} color="inherit" aria-label="Open profile menu" onClick={this.handleButtonClick}>
          <div className={classes.avatarWrapperOuter}>
            <div className={classNames(classes.avatarWrapperInner, {
              [classes.avatarConnected]: connected,
            })}>
              {authUser ? <Avatar id={avatarId} alt={`${authUser.displayName}`} src={authUser.photoURL} className={classNames(avatarClassName, classes.avatar)}>{authUser.displayName.slice(0, 2)}</Avatar> : undefined}
            </div>
          </div>
        </IconButton>
        {renderMenu}
      </React.Fragment>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    avatarButton: {
      padding: 3,
    },
    avatar: {
      width: 31,
      height: 31,
      minWidth: 31, 
      minHeight: 31,
    },
    avatarConnected: {
      boxShadow: `0px 0px 3px 3px ${'#2ecc71'}`,
    },
    avatarWrapperOuter: {
      // borderRadius: '100%',
      // border: '2px solid white',
    },
    avatarWrapperInner: {
      borderRadius: '100%',
      border: '2px solid transparent',
    },
    presence: {
      position: 'absolute',
      bottom: '1px',
      right: '1px',
      borderRadius: '100%',
      backgroundColor: '#fff',
      width: '10px',
      height: '10px',
    },
  });

export default withStyles(styles)(withFirebase(UserAvatar));