import React, { Component } from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';

import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, InputAdornment
} from "@material-ui/core";

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import CheckIcon from '@material-ui/icons/Check';

interface ShareDialogProps extends WithStyles<typeof styles> {
    open?: boolean;
    shareUrl?: string;

    onClose?: () => void;
}

interface ShareDialogState {
    copiedShare?: boolean;
}

class ShareDialog extends Component<ShareDialogProps, ShareDialogState> {
    constructor(props: ShareDialogProps) {
        super(props);
        this.state = {

        }
    }

    handleCloseShare = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
    }

    handleNativeShare = (url: string) => {
        if ((navigator as any).share) {
            (navigator as any).share({
                title: 'Chachoka.com',
                text: 'Check out this game of Chachoka!',
                url: url
            })
                .then(() => console.log('Successful share'))
        }
    }

    render() {
        const { classes, open, shareUrl } = this.props;
        const { copiedShare } = this.state;

        return (
            <Dialog open={open || false} onClose={this.handleCloseShare} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Share</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Use the URL below to share this game with your friends.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="url"
                        type="text"
                        fullWidth
                        value={shareUrl}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CopyToClipboard text={shareUrl}
                                        onCopy={() => this.setState({ copiedShare: true })}>
                                        <Button color="primary">
                                            {copiedShare ? <CheckIcon className={classes.rightIcon} /> : undefined}
                                            Copy
                                </Button>
                                    </CopyToClipboard>
                                </InputAdornment>
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    {(navigator as any).share ? <Button onClick={() => this.handleNativeShare(shareUrl)} color="primary">
                        Share
                    </Button> : undefined}
                    <Button onClick={this.handleCloseShare} color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


const styles = (theme: Theme) =>
    createStyles({
        rightIcon: {
            marginRight: theme.spacing(1),
        },
    });


export default withStyles(styles)(ShareDialog);